import React from 'react';
import { FiCheckCircle, FiCircle } from 'react-icons/fi';
import { useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { AdBoardSectionType } from '@keyops-hcp/constants';
import { KeyopsSubTitle2 } from '../Headers';

interface ActivityListItemProps {
  text: string;
  sectionId: string;
  sectionType: AdBoardSectionType;
  finished: boolean;
  accessible: boolean;
  discussionId?: string; // Only needed for discussions section
  setCurrentSectionId: (id: string) => void;
  setDisplayedDiscussionId?: React.Dispatch<React.SetStateAction<string>>; //Only needed for discussions section
  setDiscussionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ActivityListItem = ({
  text,
  sectionId,
  sectionType,
  finished,
  setCurrentSectionId,
  accessible,
  discussionId,
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
}: ActivityListItemProps): JSX.Element => {
  const { palette } = useTheme();
  const { sectionId: urlSectionId } = useParams();

  let selected = false;
  if (urlSectionId && urlSectionId === sectionId) selected = true;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexGrow={1}
      gap={1}
      pl={5}
      pr={2}
      py={1}
      sx={{
        cursor: accessible ? 'pointer' : 'default',
        backgroundColor: selected ? palette.keyops.gray[50] : 'transparent',
        '&:hover': {
          backgroundColor: accessible
            ? palette.keyops.gray[100]
            : 'transparent',
        },
        opacity: accessible ? 1 : 0.5, // Grays out the entire Box
      }}
      onClick={() => {
        // TODO: fix to be able to open the discussion drawer over the other activity if discussion is a 2nd activiy in a groupSection
        // For now, it will navigate to the section id and open the drawer over a blank page
        // Handling of the discussion drawer
        // if DiscussionSections, selecting a discussion will open the drawer to the discussion's msgs
        // for all other types, the drawer is closed and the selected discussion reset to undefined
        if (accessible && sectionType === 'discussion') {
          setCurrentSectionId(sectionId);

          if (setDiscussionDrawerOpen && setDisplayedDiscussionId) {
            setDiscussionDrawerOpen(true);
            setDisplayedDiscussionId(discussionId);
          } else {
            setDiscussionDrawerOpen(false);
            setDisplayedDiscussionId(undefined);
          }
        }
        if (accessible) {
          setCurrentSectionId(sectionId);
        }
      }}
    >
      {finished ? (
        <FiCheckCircle
          data-testid='check-circle-icon'
          fontSize={20}
          color={palette.keyops.teal}
          style={{ minWidth: 20 }}
        />
      ) : (
        <FiCircle
          data-testid='circle-icon'
          fontSize={18}
          style={{ minWidth: 20 }}
        />
      )}
      <KeyopsSubTitle2
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          color: accessible ? 'inherit' : palette.keyops.gray[500], // Grays out the text
        }}
      >
        {text}
      </KeyopsSubTitle2>
    </Box>
  );
};
