import React, { useState } from 'react';
import { Box, Collapse, useMediaQuery, useTheme } from '@mui/material';

import { SectionNavButtons } from '../SectionNavButtons';
import { SectionNavigationContent } from '../SectionNavigationContent';

interface SectionsDrawerProps {
  setDisplayedDiscussionId: React.Dispatch<React.SetStateAction<string>>;
  setDiscussionDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSectionId: (id: string) => void;
}

export const SectionsDrawer = ({
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
  // To be able to navigate in admin preview
  setCurrentSectionId,
}: SectionsDrawerProps): JSX.Element => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [sectionDrawerOpen, setSectionDrawerOpen] = useState(true);

  const handleSectionDrawerToggle = () => {
    setSectionDrawerOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box
      display={!isMobile ? 'flex' : 'none'}
      flexDirection='column'
      alignItems='flex-start'
      height={'100%'}
    >
      {!sectionDrawerOpen && (
        <SectionNavButtons
          sectionDrawerOpen={sectionDrawerOpen}
          handleSectionDrawerToggle={handleSectionDrawerToggle}
        />
      )}

      <Collapse
        in={sectionDrawerOpen}
        timeout={300}
        orientation='horizontal'
        sx={{
          height: '100%',
          border: '1px solid var(--gray-200, #E2E8F0)',
          borderTop: 0,
        }}
      >
        <Box
          width={345}
          bgcolor='background.paper'
          overflow='auto'
          sx={{
            transform: sectionDrawerOpen ? 'scale(1)' : 'scale(0)',
          }}
        >
          <SectionNavigationContent
            handleSectionDrawerToggle={handleSectionDrawerToggle}
            setDisplayedDiscussionId={setDisplayedDiscussionId}
            setDiscussionDrawerOpen={setDiscussionDrawerOpen}
            setCurrentSectionId={setCurrentSectionId}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
