import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Zoom,
  tooltipClasses,
  IconButton,
  Tabs,
  Tab,
  useTheme,
} from '@mui/material';
import { FiDollarSign, FiClock, FiCalendar, FiInfo } from 'react-icons/fi';
import { AdBoardLandingPageProps } from './AdBoardLandingPage.types';
import { formatDateRange } from '../../../utils/datetime';
import { HTMLOutput } from '../../HTMLOutput';
import { AdBoardInviteChoiceBox } from './AdBoardInviteChoiceBox';
import i18next from '../../../languages/i18n.config';
import { AdBoardContent } from './AdBoardContent';
import { useStyles } from './styles';
import { useAdBoardInviteByAdBoardId } from '../../../hooks';
import { AdBoardOpenBox } from './AdBoardOpenBox';
import { AdBoardDeclinedBox } from './AdBoardDeclined';
import { ContractPending } from './ContractPending';
import { AdBoardUpcomingBox } from './AdBoardUpcomingBox';
import { analytics } from '../../../utils/analytics';

export const AdBoardLandingPage: React.FC<AdBoardLandingPageProps> = ({
  adBoardId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [view, setView] = React.useState('objectives');
  const {
    data: adBoardInvite,
    isLoading,
    error,
  } = useAdBoardInviteByAdBoardId(adBoardId);

  if (error) return <div>{i18next.t('common.error')}</div>;
  if (isLoading || !adBoardInvite || !adBoardInvite.adBoard) {
    return <div>{i18next.t('common.loading')}</div>; // TODO: use loading component & update test
  }

  const adBoard = adBoardInvite.adBoard;

  analytics.track('AdBoard Landing Page', {
    title: adBoard.title,
    adBoardId,
    adBoardState: adBoard.state,
    inviteState: adBoardInvite.state,
  });
  const tabs = [
    {
      value: 'objectives',
      label: i18next.t('ad_board.landing_page.objectives'),
    },
    { value: 'contents', label: i18next.t('ad_board.landing_page.contents') },
    // TODO: add moderators
    // {
    //   value: 'moderators',
    //   label: i18next.t('ad_board.landing_page.moderators'),
    // },
  ];

  const totalEstimatedTime = adBoard.sectionGroups.reduce(
    (acc, sectionGroup) => {
      return acc + sectionGroup?.estimatedTime;
    },
    0
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  //TODO: need to factor in adboard state at some point
  const ctaBox = (function () {
    switch (adBoardInvite.state) {
      case 'sent':
        return <AdBoardInviteChoiceBox adBoardId={adBoard.id} />;
      case 'accepted':
        return <ContractPending />;
      case 'contract-signed': {
        if (adBoard.state === 'live') {
          return (
            <AdBoardOpenBox endDate={adBoard.endDate} adBoardId={adBoard.id} />
          );
        }
        return <AdBoardUpcomingBox startDate={adBoard.startDate} />;
      }
      case 'declined':
        return <AdBoardDeclinedBox />;
      default:
        return <></>;
    }
  })();

  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.detailsContainer}>
          <Typography sx={styles.title}>{adBoard.title}</Typography>
          <Typography sx={styles.subTitle}>
            {i18next.t('ad_board.landing_page.company_invite', {
              name: adBoard.company?.name,
            })}
          </Typography>
          <Box sx={styles.detailsBox}>
            <Box sx={styles.detailsInfo}>
              <FiDollarSign style={styles.iconStyle} />
              <Typography sx={styles.inviteDetails}>
                {i18next.t('ad_board.landing_page.earn', {
                  amount: adBoardInvite.honorarium,
                })}
              </Typography>
            </Box>
            <Box sx={styles.detailsInfo}>
              <FiClock style={styles.iconStyle} />
              <Typography sx={styles.inviteDetails}>
                {totalEstimatedTime}{' '}
                {totalEstimatedTime !== 1 ? 'minutes' : 'minute'}
              </Typography>
              <Tooltip
                placement='top'
                TransitionComponent={Zoom}
                title={
                  <>
                    <Typography variant='body2' textAlign='center'>
                      {i18next.t('ad_board.landing_page.tooltip1')}
                    </Typography>
                    <Typography variant='body2' textAlign='center'>
                      {i18next.t('ad_board.landing_page.tooltip2')}
                    </Typography>
                  </>
                }
                arrow
                sx={{
                  [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 250,
                  },
                }}
              >
                <IconButton sx={styles.estimatedTimeToolTip}>
                  <FiInfo />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={styles.detailsInfo}>
              <FiCalendar style={styles.iconStyle} />
              <Typography sx={styles.inviteDetails}>
                {formatDateRange(adBoard.startDate, adBoard.endDate)}
              </Typography>
            </Box>
          </Box>
          <Typography sx={styles.description}>{adBoard.description}</Typography>
        </Box>
        {ctaBox}
      </Box>
      {/* Tabs */}
      <Box sx={{ pt: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={view} onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                aria-label={tab.label}
                sx={{
                  ...styles.subTitle,
                  color:
                    view === tab.value
                      ? theme.palette.keyops.blue.main
                      : theme.palette.keyops.black.main,
                  fontSize: '20px',
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={styles.content}>
          {view === 'objectives' && (
            <Typography>
              <HTMLOutput html={adBoard.objectives} />
            </Typography>
          )}
          {view === 'contents' && <AdBoardContent adBoardId={adBoard.id} />}
          {view === 'moderators' && (
            <Typography>
              <HTMLOutput html={adBoard.moderators} />
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
