import { SectionDto } from '@keyops-hcp/dtos';

export const getCurrentSection = (
  sectionId: string,
  sections: SectionDto[]
) => {
  if (!sections) return undefined;
  //if it's "first" or undefined, we just jump to first section
  if ((!sectionId || sectionId === 'first') && sections.length > 0) {
    return sections[0];
  }

  //TODO: probably remove
  //if it's "current" we go to the latest that the user has unlocked
  if (sectionId === 'current') {
    console.log('trying to treat as current');
    const currentForUser = 0; //TODO: base on user progress
    return sections[currentForUser];
  }

  //otherwise, we try to use it as a section id
  //if we still can't find it, return the first
  return sections.find((section) => section.id === sectionId) ?? sections[0];
};
