import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { FiList, FiMessageCircle } from 'react-icons/fi';

import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';
import { Attendees } from '../Attendees';
import { Notifications } from '../Notifications';
import { useAdBoardContext } from '../../context';

interface MobileAdBoardTopBarProps {
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

export const MobileAdBoardTopBar = ({
  toggleDiscussionDrawer,
}: MobileAdBoardTopBarProps): JSX.Element => {
  const { palette } = useTheme();
  const { isAdmin } = useAdBoardContext();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <IconButton
        data-testid='list-icon'
        sx={{ color: palette.keyops.blue.dark }}
      >
        <FiList fontSize={25} />
      </IconButton>
      <Attendees />
      {COMPONENT_FEATURE_FLAGS.ADBOARD_DISCUSSION && (
        <IconButton
          data-testid='discussion-board-button'
          aria-label='discussion-board-button'
          sx={{ color: palette.keyops.blue.dark, mt: -0.2 }}
          onClick={() => toggleDiscussionDrawer(false)} // TODO: add discussion board state change here
        >
          <FiMessageCircle fontSize={25} />
        </IconButton>
      )}
      {/* No need to display the Notification in the Admin Preview */}
      {!isAdmin && <Notifications />}
    </Box>
  );
};
