import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Box, Divider, useTheme } from '@mui/material';

import { DiscussionDto } from '@keyops-hcp/dtos';

import i18next from '../../languages/i18n.config';
import { KeyopsHeader3, KeyopsSubTitle2 } from '../Headers';

interface DiscussionPreviewProps {
  discussion: DiscussionDto;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  activityTitle?: string;
  sectionGroupTitle?: string;
  sectionGroupIndex?: number;
  index?: number;
}

export const DiscussionPreview = ({
  discussion,
  setDisplayedDiscussionId,
  activityTitle,
  sectionGroupTitle,
  sectionGroupIndex,
  index,
}: DiscussionPreviewProps): JSX.Element => {
  const { palette } = useTheme();
  // Not the same behavior for discussion as a section activiy or adboard's general discussion
  const isGeneralDiscussion =
    discussion.topic ===
    i18next.t('ad_board.discussionsDrawer.generalDiscussion.title');

  return (
    <Box
      flexGrow={1}
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: palette.keyops.gray[100] },
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexGrow={1}
        gap={1}
        p={1}
        pl={'16px'}
        onClick={
          () => setDisplayedDiscussionId(discussion.id) // display the discussion messages
        }
      >
        <Box display={'block'} alignItems={'center'}>
          <KeyopsHeader3 fontSize={`16px`} fontWeight={`400`} pb={1}>
            {isGeneralDiscussion ? discussion.topic : activityTitle}
          </KeyopsHeader3>
          <KeyopsSubTitle2
            color={palette.keyops.gray[600]}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {/* Display the section index if not general discussion */}
            {isGeneralDiscussion && !index
              ? i18next.t(
                  'ad_board.discussionsDrawer.generalDiscussion.helperMessageShort'
                )
              : `${i18next.t('ad_board.sections_view.title')} ${
                  (sectionGroupIndex as number) + 1
                } | ${sectionGroupTitle}`}
          </KeyopsSubTitle2>
        </Box>

        <FiChevronRight fontSize={20} color={palette.keyops.gray[600]} />
      </Box>
      <Divider />
    </Box>
  );
};
