import React, { useState, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AdBoardSectionProgressDto,
  AdBoardSectionGroupDto,
  SectionDto,
} from '@keyops-hcp/dtos';
import { ActivityListItem } from '../ActivityListItem';
import { KeyopsHeader3 } from '../Headers';

interface SectionActivitiesViewProps {
  sectionGroup: AdBoardSectionGroupDto;
  sectionProgress: AdBoardSectionProgressDto[];
  finishedSections: AdBoardSectionProgressDto[];
  isAdmin: boolean;
  setCurrentSectionId: (id: string) => void;
  setDisplayedDiscussionId: React.Dispatch<React.SetStateAction<string>>;
  setDiscussionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionActivitiesView = ({
  sectionGroup,
  sectionProgress,
  finishedSections,
  isAdmin,
  setCurrentSectionId,
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
}: SectionActivitiesViewProps): JSX.Element => {
  // Add the expanded state here
  const [expanded, setExpanded] = useState<boolean>(true);
  // Toggle function to expand/collapse the accordion
  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const ContentItem = ({ section }: { section: SectionDto }) => {
    // Check if the section is finished
    const finished = useMemo(
      () =>
        !!finishedSections.find(
          (finishedSection) => finishedSection.sectionId === section.id
        ),
      [finishedSections, section.id]
    );

    // Check if the section is available
    const accessible = useMemo(
      () =>
        !!sectionProgress?.find(
          (sectionProgress) =>
            sectionProgress.sectionId === section.id &&
            sectionProgress.accessible
        ),
      [sectionProgress, section.id]
    );
    switch (section.type) {
      case 'discussion': {
        return (
          <ActivityListItem
            key={section.id}
            text={section.title}
            sectionId={section.id}
            sectionType={section.type}
            finished={finished}
            accessible={accessible || isAdmin}
            discussionId={section?.content[0]?.discussionId} // use first topic
            setCurrentSectionId={setCurrentSectionId}
            setDisplayedDiscussionId={setDisplayedDiscussionId}
            setDiscussionDrawerOpen={setDiscussionDrawerOpen}
          />
        );
      }
      default:
        return (
          <ActivityListItem
            key={section.id}
            text={section.title}
            sectionId={section.id}
            sectionType={section.type}
            finished={finished}
            accessible={accessible || isAdmin}
            setCurrentSectionId={setCurrentSectionId}
            setDisplayedDiscussionId={setDisplayedDiscussionId}
            setDiscussionDrawerOpen={setDiscussionDrawerOpen}
          />
        );
    }
  };

  return (
    <Accordion
      key={sectionGroup.id}
      elevation={0}
      disableGutters
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
      expanded={expanded}
      onChange={handleToggle} // Set the toggle function here
    >
      <AccordionSummary
        id={`${sectionGroup.id}-header`}
        aria-controls={`${sectionGroup.id}-content`}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          gap: 1,
          px: 1,
          '.MuiAccordionSummary-content': {
            my: 1.5,
          },
        }}
      >
        <KeyopsHeader3
          fontSize={16}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {sectionGroup.title || 'Untitled section'}
        </KeyopsHeader3>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {sectionGroup?.sections.map((section) => (
          <ContentItem key={section.id} section={section} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
