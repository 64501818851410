import { BaseElementProps } from '@keyops-hcp/ui-components';
import { FiTrello } from 'react-icons/fi';

import { FaRegComments } from 'react-icons/fa6';

import { Chip, Stack, Typography, useTheme } from '@mui/material';
import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';

export const EngagementTopBar = ({
  type,
  rightItem,
  children,
  ...props
}: {
  type: 'survey' | 'adBoard';
  rightItem?: React.ReactNode;
} & BaseElementProps) => {
  const theme = useTheme();
  const items = Children.toArray(children);
  const { t } = useTranslation();
  const iconStyle = {
    fontSize: '20px',
    marginRight: '8px',
    color: theme.palette.keyops.blue.dark,
  };
  return (
    <Stack
      spacing={4}
      direction="row"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Stack direction="row" spacing={'16px'}>
        <Chip
          icon={
            type === 'survey' ? (
              <FiTrello style={iconStyle} />
            ) : (
              <FaRegComments style={iconStyle} />
            )
          }
          label={t('general.' + type)}
          sx={{
            borderRadius: '4px',
            backgroundColor: theme.palette.keyops.blue.light,
            color: theme.palette.keyops.blue.dark,
            fontWeight: 600,
            '& .MuiChip-icon': {
              color: theme.palette.keyops.blue.dark,
            },
            '& .MuiChip-label': {
              pl: '0px',
            },
          }}
        />
        {items.map((item, index) => (
          <Typography
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: { xs: '12px', sm: '12px', md: '14px' },
              color: theme.palette.keyops.blue.dark,
            }}
          >
            {item}
          </Typography>
        ))}
      </Stack>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: { xs: '12px', sm: '12px', md: '14px' },
          color: theme.palette.keyops.blue.dark,
        }}
      >
        {rightItem}
      </Typography>
    </Stack>
  );
};
