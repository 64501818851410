import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';

import { DiscussionMessageDto } from '@keyops-hcp/dtos';

import { getMinutesAgoFromString } from '../../utils/datetime';
import { formatAuthorName } from '../../utils/common-utils';

interface DiscussionMessageProps {
  message: DiscussionMessageDto;
}

export const DiscussionMessage = ({
  message,
}: DiscussionMessageProps): JSX.Element => {
  const isAdmin = !!message.adminId;

  return (
    <Box pb={`8px`}>
      {/* Author avatar */}
      <Stack pb={`8px`} direction={'row'} spacing={1}>
        <Avatar
          sx={{
            backgroundColor: `${
              isAdmin ? 'keyops.blue.dark' : 'keyops.gray.400'
            }`,
            color: 'white',
            width: '40px',
            height: '40px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          {formatAuthorName(message, true)}
        </Avatar>
        {/* TODO: generate a profil pic with initials */}
        <Box>
          <Stack pb={`8px`} direction={'row'} spacing={2}>
            {/* TODO: format according hcpType */}
            <Typography fontWeight={500} fontSize={`16px`} lineHeight={`24px`}>
              {formatAuthorName(message, false)}
            </Typography>
            {/* TODO: format dates */}
            <Typography
              fontWeight={400}
              fontSize={`14px`}
              lineHeight={`24px`}
              color={'keyops.gray.600'}
            >
              {getMinutesAgoFromString(message.createdAt.toString())}
            </Typography>
          </Stack>
          <Box>
            <Typography>{message.content}</Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
