import React from 'react';
import { useParams } from 'react-router';
import { Divider } from '@mui/material';

import {
  AdBoardSectionGroupDto,
  AllCurrentDiscussionsDto,
  DiscussionDto,
} from '@keyops-hcp/dtos';

import { Loader } from '../Loader';
import { DiscussionDrawerHeader } from '../DiscussionDrawerHeader';
import { DiscussionChat } from '../DiscussionChat';
import { DiscussionPreview } from '../DiscussionPreview';
import { HCPAdBoardContextType, useDiscussionsByAdBoardId } from '../../hooks';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';

interface DiscussionDrawerContentProps {
  displayedDiscussionId: string | undefined;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  optionalAdBoardId?: string; // Can be passed by the admin preview
  optionalCurrentSectionId?: string; // Can be passed by the admin preview
}

/**
 * Used to determine the conversation to display in allCurrentDiscussions, given a selected id
 * @param displayedDiscussionId
 * @param allCurrentDiscussions
 * @returns the discussion to display in the Discussion Drawer
 */
const findDiscussionToDisplay = (
  displayedDiscussionId: string,
  allCurrentDiscussions: AllCurrentDiscussionsDto
): DiscussionDto => {
  // Check if general discussion
  if (allCurrentDiscussions.generalDiscussion.id === displayedDiscussionId)
    return allCurrentDiscussions.generalDiscussion;

  const discussionToDisplay =
    allCurrentDiscussions.currentSectionDiscussions.find(
      (currentSectionDiscussion) =>
        currentSectionDiscussion?.discussionId === displayedDiscussionId
    )?.discussion;

  if (discussionToDisplay) return discussionToDisplay;
  console.error(
    `Discussion to display not found - passed id:`,
    displayedDiscussionId
  );
};

const getSectionsGroupInfo = (
  sectionGroupsData: AdBoardSectionGroupDto[],
  currentSectionId: string
): { sectionGroupTitle: string; sectionGroupIndex: number } => {
  const currentSG = sectionGroupsData?.find((sg) =>
    sg.sections.find((s) => s.id === currentSectionId)
  );
  const sectionGroupTitle = currentSG?.title;
  const sectionGroupIndex = currentSG?.index;

  return { sectionGroupTitle, sectionGroupIndex };
};

export const DiscussionDrawerContent = ({
  displayedDiscussionId,
  toggleDiscussionDrawer,
  setDisplayedDiscussionId,
  optionalAdBoardId,
  optionalCurrentSectionId,
}: DiscussionDrawerContentProps): JSX.Element => {
  const generalDiscussionTitle = i18next.t(
    'ad_board.discussionsDrawer.generalDiscussion.title'
  );
  // If admin, passed as props to the component.
  //  If HCP, grab them in the url
  let adBoardId = optionalAdBoardId;
  let currentSectionId = optionalCurrentSectionId;
  if (!adBoardId && !currentSectionId) {
    adBoardId = useParams().adBoardId;
    currentSectionId = useParams().sectionId;
  }

  // Fetch all current discussions (combining the Ad Board general discussion and the current section discussions, if any)
  const {
    data: allCurrentDiscussions,
    isLoading,
    isError,
  } = useDiscussionsByAdBoardId(adBoardId, currentSectionId);

  // Use sectionGroupsData to grab the title of the current sectionsGroup
  const { sectionGroupsData } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};

  const { sectionGroupTitle, sectionGroupIndex } = getSectionsGroupInfo(
    sectionGroupsData,
    currentSectionId
  );

  if (isLoading) return <Loader />;
  if (isError) return <>Something went wrong</>;
  if (!allCurrentDiscussions) return <>No data</>;

  // If a discussion has been selected, display the chat in the Discussion drawer
  if (displayedDiscussionId) {
    const displayedDiscussion = findDiscussionToDisplay(
      displayedDiscussionId,
      allCurrentDiscussions
    );

    return (
      <>
        <DiscussionDrawerHeader
          isDisplayingMessages={true}
          title={
            displayedDiscussion?.topic === generalDiscussionTitle
              ? generalDiscussionTitle
              : allCurrentDiscussions.currentSectionTitle
          }
          description={
            displayedDiscussion?.topic === generalDiscussionTitle
              ? undefined
              : allCurrentDiscussions?.currentSectionDescription
          }
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
        />
        <Divider />
        <DiscussionChat
          discussion={displayedDiscussion}
          topicsCount={allCurrentDiscussions?.currentSectionDiscussions.length}
          currentTopicIndex={
            allCurrentDiscussions?.currentSectionDiscussions.find(
              (currentSectionDiscussion) =>
                currentSectionDiscussion.discussionId === displayedDiscussionId
            )?.index
          }
          setDisplayedDiscussionId={setDisplayedDiscussionId}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
        />
      </>
    );
  }

  // If no discussion selected, display a preview of the available discussions on the current section
  return (
    <>
      <DiscussionDrawerHeader
        isDisplayingMessages={false}
        title={i18next.t('ad_board.discussionsDrawer.title')}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />
      <Divider />
      {/* General Discussion */}
      <DiscussionPreview
        discussion={allCurrentDiscussions.generalDiscussion}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />

      {/* TODO: add a way to navigate to the next discussion/topic when previous done */}
      {/* Display the first discussion (aka topic) from all current section discussions, if any */}
      {allCurrentDiscussions.currentSectionDiscussions.length > 0 && (
        <DiscussionPreview
          key={allCurrentDiscussions.currentSectionDiscussions[0].discussionId}
          discussion={
            allCurrentDiscussions.currentSectionDiscussions[0].discussion
          }
          index={allCurrentDiscussions.currentSectionIndex}
          activityTitle={allCurrentDiscussions.currentSectionTitle}
          sectionGroupTitle={sectionGroupTitle}
          sectionGroupIndex={sectionGroupIndex}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
        />
      )}
    </>
  );
};
