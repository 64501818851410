import { forOwn } from 'lodash';
import { DiscussionMessageDto } from '@keyops-hcp/dtos';

import i18next from '../../src/languages/i18n.config';

export const getUrl = (route: string, params = {}) => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

export const formatAuthorName = (
  message: DiscussionMessageDto,
  initialsOnly: boolean
): string => {
  const isAdmin = !!message.adminId;
  let formattedName: string;

  if (isAdmin) {
    // If initialsOnly is true, return the first letter of the admin's name
    formattedName = initialsOnly
      ? message.admin?.name?.[0] || i18next.t('common.na')
      : message.admin?.name || i18next.t('common.na');
  } else {
    // If initialsOnly is true, return the first letter of both user first and last names
    formattedName = initialsOnly
      ? (message.user.firstName?.[0] || i18next.t('common.na')) +
        (message.user.lastName?.[0] || '')
      : `${message.user.firstName || i18next.t('common.na')} ${
          message.user.lastName || ''
        }`;
  }

  return formattedName.trim();
};
