import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { FaArrowRight } from 'react-icons/fa6';

import {
  AdBoardDiscussionSectionProgressCombined,
  AdBoardSectionProgressDto,
  DiscussionDto,
} from '@keyops-hcp/dtos';

import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { DiscussionChatHeader } from '../DiscussionChatHeader';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType, useDiscussionsByAdBoardId } from '../../hooks';
import { AdBoardCompletedTaskButton } from '../AdBoardCompletedTaskButton';
import { getUrl } from '../../utils/common-utils';
import { ADBOARD_SECTIONS } from '../../utils/physician-ui-routes';

interface DiscussionChatProps {
  discussion: DiscussionDto;
  currentTopicIndex: number;
  topicsCount: number;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

const isCurrentDiscussionFinished = (
  currentDiscussionId: string,
  currentSectionProgress: AdBoardSectionProgressDto
) => {
  return (
    currentSectionProgress as AdBoardDiscussionSectionProgressCombined
  )?.finishedTopics?.includes(currentDiscussionId);
};

const isCurrentDiscussionSectionFinished = (
  totalDiscussionsCount: number,
  currentSectionProgress: AdBoardSectionProgressDto
) => {
  return (
    (currentSectionProgress as AdBoardDiscussionSectionProgressCombined)
      ?.finishedTopics.length === totalDiscussionsCount
  );
};

export const DiscussionChat = ({
  discussion,
  currentTopicIndex,
  topicsCount,
  setDisplayedDiscussionId,
  toggleDiscussionDrawer,
}: DiscussionChatProps): JSX.Element => {
  // Get the progress for this discussion to handle topics navigation
  const { sectionsData: sections, currentSectionProgress } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};

  const { adBoardId, sectionId } = useParams();

  // Fetch all discussions
  const { data: allDiscussions, isSuccess } = useDiscussionsByAdBoardId(
    adBoardId,
    sectionId
  );

  const navigate = useNavigate();

  // Find the index of the current section
  const [currentSectionIndex, setCurrentSectionIndex] = useState(undefined);
  useEffect(() => {
    if (sections && sections.length > 0 && sectionId) {
      setCurrentSectionIndex(
        sections.findIndex((section) => section.id === sectionId)
      );
    }
  }, [sections, sectionId]);

  const handleNavigateToNextTopic = () => {
    const nextDiscussion = allDiscussions?.currentSectionDiscussions?.find(
      (sectionDiscussion) => {
        console.log(`sectionDiscussion`, sectionDiscussion);

        return sectionDiscussion.index === currentTopicIndex + 1;
      }
    );
    // If another topic/discussion, display it in the Discussions Drawer
    if (nextDiscussion) setDisplayedDiscussionId(nextDiscussion?.discussionId);
    // If no other topic/discussion, and current one is finished, navigates to next section
    else if (
      !nextDiscussion &&
      isCurrentDiscussionSectionFinished(
        allDiscussions?.currentSectionDiscussions?.length,
        currentSectionProgress
      )
    ) {
      // If not the end, navigate to next section and close the discussion drawer
      if (currentSectionIndex !== sections.length - 1) {
        toggleDiscussionDrawer(false);
        setDisplayedDiscussionId(undefined);
        navigate(
          getUrl(ADBOARD_SECTIONS, {
            adBoardId,
            sectionId: sections[currentSectionIndex + 1].id,
          })
        );
        // AdBoard is finished
      } else {
        console.log('Finished');
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' height='90%' p={`0px 58px`}>
      {/* Only display the header for section's discussions */}
      {discussion?.topic !==
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') && (
        <DiscussionChatHeader
          discussion={discussion}
          currentTopicIndex={currentTopicIndex}
          topicsCount={topicsCount}
        />
      )}

      <DiscussionChatMessages discussion={discussion} />

      {/* Display a helper message for general discussion without any messages*/}
      {discussion?.topic ===
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') &&
        discussion?.messages.length === 0 && (
          <Typography
            textAlign='center'
            fontSize={`16px`}
            color={'keyops.gray.600'}
            pb='8px'
          >
            {i18next.t(
              'ad_board.discussionsDrawer.generalDiscussion.helperMessage'
            )}
          </Typography>
        )}
      <DiscussionMessageForm discussionId={discussion?.id} />

      {/* Next section button */}
      {isSuccess &&
        isCurrentDiscussionFinished(discussion?.id, currentSectionProgress) && (
          <AdBoardCompletedTaskButton
            buttonContent={`Next`}
            onClick={handleNavigateToNextTopic}
            endIcon={<FaArrowRight />}
          />
        )}
    </Box>
  );
};
