import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import {
  AdBoardDto,
  AdBoardParticipantsDto,
  AdBoardSectionGroupDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { getOneAdBoard } from '../api/adBoards';
import { getAllAdBoardParticipants } from '../api/participants';

export interface AdminAdBoardContextType {
  isAdmin: boolean;
  isLoading: boolean;
  isError: boolean;
  adBoardData?: AdBoardDto;
  sectionGroupsData?: AdBoardSectionGroupDto[];
  allParticipantsData?: AdBoardParticipantsDto[];
  sectionsData?: SectionDto[];
  numberOfSections?: number;
  refetchAllData?: () => void;
}

// Used for the admin adboard context
export const useAdminAdBoard = () => {
  const isAdmin = true;

  const queryClient = useQueryClient();

  const { id: adBoardId } = useParams();

  // Use useQueries to fetch adBoard, sections, and participant data simultaneously
  const [adBoardQuery, allParticipantsQuery] = useQueries({
    queries: [
      {
        queryKey: ['adBoard', adBoardId],
        queryFn: () => getOneAdBoard(adBoardId),
      },
      {
        queryKey: ['all participants', adBoardId],
        queryFn: () => getAllAdBoardParticipants(adBoardId),
      },
    ],
  });

  const isLoading = adBoardQuery.isLoading;
  const isError = adBoardQuery.isError;

  const adBoardData = adBoardQuery?.data;
  const sectionsData = adBoardData?.sections;
  const sectionGroupsData = adBoardData?.sectionGroups;
  const numberOfSections = sectionsData?.length;
  const allParticipantsData = allParticipantsQuery?.data;

  // TODO: find a way to have it again?
  // const currentSectionDetails = useMemo(
  //   () => sectionsData?.find((section: SectionDto) => section.id === sectionId),
  //   [sectionsData, sectionId]
  // );

  // Memoize the refetchAllData function
  const refetchAllData = useCallback(() => {
    queryClient.invalidateQueries(['adBoard', adBoardId]);
  }, [queryClient, adBoardId]);

  return useMemo(
    () => ({
      isAdmin,
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      allParticipantsData,
      numberOfSections,
      refetchAllData,
    }),
    [
      isAdmin,
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      allParticipantsData,
      numberOfSections,
      refetchAllData,
    ]
  );
};
