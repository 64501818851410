import React from 'react';
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FiUsers } from 'react-icons/fi';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
interface AttendeesProps {
  sx?: SxProps;
}

export const Attendees: React.FC<AttendeesProps> = ({ sx }) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { allParticipantsData, isLoading, isError } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};

  if (isLoading) return <div>{i18next.t('common.loading')}</div>;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isMobile ? (
        <IconButton
          id='attendees-button'
          data-testid='attendees-icon-button'
          aria-controls={open ? 'attendees-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            textTransform: 'initial',
            color: palette.keyops.blue.dark,
            ...sx,
          }}
          onClick={handleClick}
        >
          <FiUsers fontSize={23} />
        </IconButton>
      ) : (
        <Button
          id='attendees-button'
          aria-controls={open ? 'attendees-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            textTransform: 'initial',
            color: palette.keyops.gray[700],
            ...sx,
          }}
          onClick={handleClick}
        >
          {/* TODO: get number of attendees here. */}
          {i18next.t('ad_board.adBoardTopBar.attendees', {
            count: allParticipantsData?.length,
          })}
        </Button>
      )}

      <Menu
        id='attendees-menu'
        data-testid='attendees-menu'
        aria-labelledby='attendees-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {allParticipantsData?.map((participant, i) => (
          <MenuItem key={i} sx={{ minWidth: 240 }}>
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  color: palette.keyops.white.main,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {participant?.user?.firstName[0]}
                {participant?.user?.lastName[0]}
              </Avatar>
              <Typography variant={'body2'}>
                {participant?.user?.firstName} {participant?.user?.lastName}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
