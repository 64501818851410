import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { KeyopsSubTitle1 } from '../Headers';
import { VideoCommentsDisplayProps } from './VideoCommentsDisplay.types';
import { getVideoTime, getTimeSince } from '../../utils/datetime';
import { getUserDisplayName } from '../../utils/user';
import i18next from '../../languages/i18n.config';

export const VideoCommentsDisplay = ({
  videoComments,
  renderMentionsText,
  loading,
  error,
}: VideoCommentsDisplayProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const jumpToTime = (timestamp: number) => {
    if (timestamp !== undefined && timestamp !== null) {
      setSearchParams({ videoTime: timestamp.toString() });
    }
  };

  const textStyle = {
    color: theme.palette.keyops.black.main,
  };
  const smallTextStyle = {
    ...textStyle,
    fontSize: '14px',
  };

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error)
    return (
      <div>
        {i18next.t('common.error')} {error}
      </div>
    );
  return (
    <Box>
      <KeyopsSubTitle1>
        {i18next.t('video.comments.count', { count: videoComments.length })}
      </KeyopsSubTitle1>
      {videoComments.map((videoComment) => (
        //TODO: pull this into component to display individual
        <Box key={videoComment.id} sx={{ my: 4 }}>
          <Stack direction='row'>
            <Avatar
              sx={{
                backgroundColor: theme.palette.keyops.gray[400],
                color: theme.palette.keyops.white.main,
                width: '40px',
                height: '40px',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {videoComment.user?.firstName?.length > 0
                ? videoComment.user.firstName[0]
                : ''}
              {videoComment.user?.lastName?.length > 0
                ? videoComment.user.lastName[0]
                : ''}
            </Avatar>
            <Stack>
              <Container>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={smallTextStyle}>
                    {getUserDisplayName(videoComment.user)}
                  </Typography>
                  <Typography sx={smallTextStyle}>
                    <Link
                      component='button'
                      sx={{ textDecoration: 'none' }}
                      onClick={() => jumpToTime(videoComment.timestamp)}
                    >
                      {getVideoTime(videoComment.timestamp)}
                    </Link>
                  </Typography>
                  {/*TODO: link to timestamp */}
                  <Typography sx={smallTextStyle}>
                    {getTimeSince(videoComment.createdAt)}
                  </Typography>
                </Stack>
              </Container>
              <Container>
                <Typography sx={textStyle}>
                  {renderMentionsText
                    ? renderMentionsText(videoComment.text)
                    : videoComment.text}
                </Typography>
              </Container>
              <Container>
                {/*TODO: link to reply to comment goes here */}
              </Container>
            </Stack>
          </Stack>
        </Box>
      ))}
      {videoComments.length === 0 && (
        <Container>
          <KeyopsSubTitle1>{i18next.t('video.comments.empty')}</KeyopsSubTitle1>
        </Container>
      )}
    </Box>
  );
};
