import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

import { Loader } from '../Loader';
import { HCPAdBoardContextType, usePostDiscussionMessage } from '../../hooks';
import { theme } from '../../theme';
import { useAdBoardContext } from '../../context';
import { AdBoardDiscussionSectionProgressDto } from '@keyops-hcp/dtos';
import i18next from '../../languages/i18n.config';
import { analytics } from '../../utils/analytics';

interface DiscussionMessageFormProps {
  discussionId: string;
}

export const DiscussionMessageForm = ({
  discussionId,
}: DiscussionMessageFormProps): JSX.Element => {
  const { currentSectionProgress, updateProgress = () => {} } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};
  const sectionProgress =
    currentSectionProgress as AdBoardDiscussionSectionProgressDto;

  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: undefined,
    },
  });

  const handlePostMessage: SubmitHandler<{ message: string }> = async (
    messageTosave
  ) => {
    postDiscussionMessage({ ...messageTosave, discussionId });
    const existingFinishedTopics = sectionProgress?.finishedTopics || [];
    if (!existingFinishedTopics.includes(discussionId) && updateProgress) {
      const finishedTopics = [...existingFinishedTopics, discussionId];
      analytics.track(`AdBoard Progress Discussion`, { finishedTopics });
      updateProgress({
        finishedTopics,
      });
    }
    reset();
  };

  // Post one message (and invalidate the get all discussions in the mutation itself)
  const {
    mutate: postDiscussionMessage,
    isLoading,
    isError,
  } = usePostDiscussionMessage();

  if (isError) return <>Something went wrong</>;

  return (
    <Box width={'100%'} alignSelf={'center'}>
      <form onSubmit={handleSubmit(handlePostMessage)}>
        {isLoading ? (
          <Loader />
        ) : (
          <FormGroup>
            {/* TODO:improve the style */}
            <FormControl variant='outlined'>
              <OutlinedInput
                id='discussion-message-message-input'
                aria-describedby='discussion-message-message-input'
                placeholder={i18next.t('forms.helperMessage')}
                multiline
                fullWidth
                rows={5}
                maxRows={10}
                {...register('message')}
                sx={{
                  paddingBottom: `8px`,
                  position: 'relative',
                  borderRadius: '8px',
                  borderColor: theme.palette.keyops.gray[400],
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.keyops.blue.dark,
                      borderWidth: '1px',
                    },
                  },
                }}
                endAdornment={
                  <Button
                    type='submit'
                    variant='outlined'
                    sx={{
                      position: 'absolute',
                      right: '8px',
                      bottom: '8px',
                      color: theme.palette.keyops.blue.dark,
                      borderColor: theme.palette.keyops.blue.dark,
                      '&:hover': {
                        borderColor: theme.palette.keyops.blue.dark,
                        backgroundColor: theme.palette.keyops.blue.dark,
                        color: 'white',
                      },
                    }}
                  >
                    {i18next.t('common.send')}
                  </Button>
                }
              />

              {errors.message && (
                <FormHelperText>{errors.message.message}</FormHelperText>
              )}
            </FormControl>
          </FormGroup>
        )}
      </form>
    </Box>
  );
};
