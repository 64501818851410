// External Dependencies
import React, { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { getCompletedInvitationsWithReferrals } from '../../../../../utils/functions/invitation-utils';
import ReferralOpportunitiesCard from './ReferralOpportunitiesCard';

type ReferralOpportunitiesSectionProps = {
  userData: UserDto;
  engagementData: EngagementData;
};

const ReferralOpportunitiesSection = ({
  engagementData,
}: ReferralOpportunitiesSectionProps) => {
  // Config
  const theme = useTheme();

  const { t } = useTranslation();

  // Functions
  const engagementsWithReferrals = useMemo(
    () =>
      getCompletedInvitationsWithReferrals(engagementData.surveyInvitations),
    [engagementData]
  );
  // Render
  const cards = engagementsWithReferrals?.map(
    ({ engagementId, engagement }) => {
      return (
        <Grid key={engagementId} item xs={12}>
          <ReferralOpportunitiesCard
            engagementId={engagementId}
            title={engagement.title}
            payout={engagement.referralPayout}
            points={engagement.referralRewardValue}
          />
        </Grid>
      );
    }
  );

  return (
    <>
      {engagementsWithReferrals.length > 0 ? (
        <>
          <Grid columnSpacing={3} rowSpacing={2} container>
            {cards}
          </Grid>
        </>
      ) : (
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
          }}
        >
          {t('dashboard.referralOpportunitiesSection.emptyMessage')}
        </Typography>
      )}
    </>
  );
};

export default ReferralOpportunitiesSection;
