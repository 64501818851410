import React, { useEffect, useCallback, useRef, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

import { useVideo } from '../../hooks/useVideo';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
import { analytics } from '../../utils/analytics';

export const Video = forwardRef<
  ReactPlayer,
  {
    videoId: string;
  }
>(({ videoId }, playerRef) => {
  const { video, loading, error } = useVideo(videoId);

  const playerContainerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const videoTime = parseInt(searchParams.get('videoTime'));

  const context = useAdBoardContext() as HCPAdBoardContextType;
  // If the user is admin, then updateProgress is undefined
  const { updateProgress = () => {} } = context || {};

  const jumpToVideoTime = () => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (videoTime) {
        player.seekTo(videoTime, 'seconds');
      }
    }
  };

  useEffect(jumpToVideoTime, [videoTime, playerRef]);

  const onReady = useCallback(jumpToVideoTime, [videoTime, playerRef]);

  const updateVideoProgress = () => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (updateProgress) {
        const currentTime = player.getCurrentTime();
        analytics.track(`AdBoard Progress Video`, { currentTime });
        updateProgress({ currentTime: player.getCurrentTime() });
      }
    }
  };

  const onPause = useCallback(updateVideoProgress, [playerRef, updateProgress]);

  const onEnded = useCallback(updateVideoProgress, [playerRef, updateProgress]);

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error) return <div>{i18next.t('common.error')}</div>;

  return (
    <Box>
      <Box ref={playerContainerRef} display={'block'} margin={'auto'} mt={2}>
        <ReactPlayer
          url={video?.link}
          ref={playerRef}
          controls
          onStart={() => console.log('starting video')}
          onPause={onPause}
          onReady={onReady}
          onEnded={onEnded}
          width={'98%'}
          height={'450px'}
        />
      </Box>
    </Box>
  );
});

Video.displayName = 'Video';
