import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DiscussionDto } from '@keyops-hcp/dtos';

import { KeyopsHeader3 } from '../Headers';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
import i18next from '../../languages/i18n.config';

interface DiscussionChatHeaderProps {
  discussion: DiscussionDto;
  currentTopicIndex: number;
  topicsCount: number;
}

export const DiscussionChatHeader = ({
  discussion,
  currentTopicIndex,
  topicsCount,
}: DiscussionChatHeaderProps): JSX.Element => {
  return (
    <Box display={'block'} pt={2}>
      <KeyopsHeader3
        pb={1}
        fontSize={'14px'}
        fontWeight={500}
        fontColor={'keyops.gray[700]'}
      >
        {/* If many topics, display different wording */}
        {topicsCount === 1
          ? i18next.t('ad_board.discussionsDrawer.topic')
          : i18next.t('ad_board.discussionsDrawer.topic_number', {
              currentTopicIndex: currentTopicIndex + 1,
              totalTopics: topicsCount,
            })}
      </KeyopsHeader3>
      <KeyopsHeader3 pb={1} fontSize={'16px'} fontWeight={400}>
        {discussion?.topic}
      </KeyopsHeader3>

      <AdBoardSectionTaskInfo pb='8px' mb='16px'>
        {i18next.t('ad_board.discussionsDrawer.objectives')}
      </AdBoardSectionTaskInfo>

      <Divider />
    </Box>
  );
};
