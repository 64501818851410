import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DiscussionDrawerHeaderProps {
  title: string;
  description?: string;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  isDisplayingMessages: boolean;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const DiscussionDrawerHeader = ({
  title,
  description,
  toggleDiscussionDrawer,
  isDisplayingMessages,
  setDisplayedDiscussionId,
}: DiscussionDrawerHeaderProps): JSX.Element => {
  return (
    <Box
      p={2}
      display={'flex'}
      justifyContent={isDisplayingMessages ? 'left' : 'space-between'}
      alignItems={'center'}
    >
      {/* Discussion nav title */}
      {isDisplayingMessages && (
        <IconButton
          size='small'
          onClick={() => {
            setDisplayedDiscussionId(undefined);
          }}
        >
          <FiChevronLeft style={{ fontSize: 16 }} />
        </IconButton>
      )}
      <Box pl={isDisplayingMessages ? `16px` : '0'} display={'block'}>
        <Typography variant='body1' fontWeight={500}>
          {title}
        </Typography>
        {description && (
          <Typography fontSize={`14px`} fontWeight={400}>
            {description}
          </Typography>
        )}
      </Box>
      {!isDisplayingMessages && (
        <IconButton size='small' onClick={() => toggleDiscussionDrawer(false)}>
          <CloseIcon style={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Box>
  );
};
