import React, { useEffect } from 'react';
import { Stack } from '@mui/material';

import { DiscussionDto, DiscussionMessageDto } from '@keyops-hcp/dtos';
import { DiscussionMessage } from '../DiscussionMessage';

interface DiscussionChatMessagesProps {
  discussion: DiscussionDto;
}

// To auto scroll to the last posted message
// TODO: put in ui-components
const useChatScroll = (
  dep: DiscussionMessageDto[]
): React.MutableRefObject<HTMLDivElement | undefined> => {
  const ref = React.useRef<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
};

export const DiscussionChatMessages = ({
  discussion,
}: DiscussionChatMessagesProps): JSX.Element => {
  const ref = useChatScroll(discussion?.messages);

  return (
    <Stack
      direction={'column'}
      alignContent={'flex-start'}
      ref={ref}
      flexGrow={1} // This allows the content to take up remaining space
      overflow='auto' // Enables scrolling for chat content
      p={'40px 0'}
      sx={{
        // Since justifyContent={'flex-end'} Won't work with the scroll
        '> :first-of-type': {
          marginTop: 'auto !important',
          /* use !important to prevent breakage from child margin settings */
        },
      }}
    >
      {discussion &&
        discussion?.messages?.map((message) => (
          <DiscussionMessage key={message.id} message={message} />
        ))}{' '}
    </Stack>
  );
};
