// External Dependencies
import React from 'react';
import {
  Typography,
  Box,
  useTheme,
  Stack,
  IconButton,
  useMediaQuery,
} from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { EngagementCard } from './EngagementCard';
import ReferralCardStar from '../../../../../images/ReferralCardStar.svg';
import { FiLink, FiMail, FiShare } from 'react-icons/fi';
import useReferralShareOptions from '../../../../../custom-hooks/useReferralShareOptions';
import Loading from '../../../../../components/Loading';

type ReferralOpportunitiesCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  points?: number;
};

const ReferralOpportunitiesCard = ({
  engagementId,
  title,
  payout,
  points,
}: ReferralOpportunitiesCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const { isLoading, isError, handleCopyClick, mailToClick, mobileShare } =
    useReferralShareOptions(engagementId);

  // 1. return Completed Engagement Row component
  return (
    <EngagementCard
      onClick={() => isMobile && mobileShare()}
      actionLabel={isMobile && t('general.share')}
      actionLabelIcon={isMobile && FiShare}
      sx={{ px: 3.5, py: 2.5 }}
    >
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={2}>
            <Typography
              fontSize="14px"
              fontWeight={600}
              color={theme.palette.keyops.blue.dark}
              width={80}
            >
              {t('dashboard.activeEngagementsSection.earn')} ${payout}
            </Typography>
            {points && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <img
                  src={ReferralCardStar}
                  height={16}
                  alt="Referral-Card-Star"
                />
                <Typography
                  fontSize="14px"
                  fontWeight={600}
                  color={theme.palette.keyops.blue.dark}
                  width={80}
                >
                  {points}
                </Typography>
              </Stack>
            )}
          </Stack>
          {/* Engagement title */}
          <Box>
            <Typography
              variant="body1"
              color={theme.palette.keyops.black.main}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Stack>
        {!isMobile && (
          <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <IconButton
              data-testid="copy-link-btn"
              onClick={handleCopyClick}
              disabled={isError}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <FiLink fontSize={28} color={theme.palette.keyops.blue.dark} />
              )}
            </IconButton>
            <IconButton
              data-testid="mail-btn"
              onClick={mailToClick}
              disabled={isError}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <FiMail fontSize={32} color={theme.palette.keyops.blue.dark} />
              )}
            </IconButton>
          </Stack>
        )}
      </Box>
    </EngagementCard>
  );
};

export default ReferralOpportunitiesCard;
