import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { FiCheckCircle } from 'react-icons/fi';
import {
  AdBoardSurveySectionProgressCombined,
  SurveyAdBoardSectionDto,
} from '@keyops-hcp/dtos';
import i18next from '../../languages/i18n.config';
import { Survey } from '../Survey';
import { useSurveyUniqueLink } from '../../hooks/useSurveyUniqueLink';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { analytics } from '../../utils/analytics';

export const SurveyWrapper = () => {
  const theme = useTheme();
  const {
    isAdmin,
    isLoading: contextLoading,
    isError: contextError,
    userId,
    currentSectionDetails,
    currentSectionProgress,
    updateProgress,
  } = (useAdBoardContext() as HCPAdBoardContextType) || {}; // Necessary to avoid bug if admin
  const [editAnswers, setEditAnswers] = useState<boolean>(false);

  // Ensure hooks are always called before returning
  const progressLink = useMemo(() => {
    return (currentSectionProgress as AdBoardSurveySectionProgressCombined)
      ?.uniqueLink;
  }, [currentSectionProgress]);

  const { data: uniqueLink } = useSurveyUniqueLink(
    (currentSectionDetails as SurveyAdBoardSectionDto)?.content?.surveyId,
    !progressLink // Conditionally enable fetch based on progressLink
  );

  // Use `useRef` to store a stable reference to the current link
  const linkRef = useRef<string | undefined>(progressLink || uniqueLink);

  // Update the ref whenever the `link` changes
  useEffect(() => {
    linkRef.current = progressLink || uniqueLink;
  }, [progressLink, uniqueLink]);

  // Conditionally update progress if we fetched a new uniqueLink
  useEffect(() => {
    if (!progressLink && uniqueLink) {
      analytics.track(`AdBoard Progress Survey`, { finished: false });
      updateProgress({ uniqueLink });
    }
  }, [uniqueLink, progressLink, updateProgress]);

  // Add event listener for survey completion message
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data?.type === 'surveyCompleted' && linkRef.current) {
        setEditAnswers(false);
        analytics.track(`AdBoard Progress Survey`, { finished: true });
        updateProgress({ uniqueLink: linkRef.current });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [updateProgress]);

  // Handle loading and error states after hooks have been called
  if (contextLoading) return <div>{i18next.t('common.loading')}</div>;
  if (contextError) return <div>{i18next.t('common.error')}</div>;
  if (!currentSectionDetails || currentSectionDetails.type !== 'survey')
    return <div>{i18next.t('common.error')}</div>;

  // Use the link from progress or fallback to the fetched link
  const link = progressLink || uniqueLink;

  // Render the Survey component
  if (isAdmin) return <Survey link='' />; //TODO just show a message

  if (currentSectionProgress?.finished && !editAnswers)
    return (
      <Box
        display='flex'
        height={'70%'}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        gap={2}
      >
        {/* Check Circle */}
        <Box
          sx={{
            width: { xs: 65, md: '114px' },
            height: { xs: 65, md: '114px' },
          }}
        >
          <FiCheckCircle color={theme.palette.keyops.teal} size='100%' />
        </Box>

        {/* Title */}
        <Typography fontWeight={600} fontSize={{ xs: 18, md: 24 }}>
          {i18next.t('survey.surveyComplete')}
        </Typography>

        {/* Button to Edit Answers */}
        <Button
          variant='outlined'
          onClick={() => {
            setEditAnswers(true);
          }}
        >
          {i18next.t('survey.editAnswers')}
        </Button>
      </Box>
    );

  return (
    <>
      {!currentSectionProgress?.finished && (
        <AdBoardSectionTaskInfo>
          {i18next.t('survey.infoText')}
        </AdBoardSectionTaskInfo>
      )}
      <Box sx={{ mt: 1, flex: 1, position: 'relative' }}>
        <Survey link={`${link}?Contact_HcpId=${userId}`} />
      </Box>
    </>
  );
};
