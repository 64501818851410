// External Dependencies
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme, Stack } from '@mui/material';
import { FiCheckCircle, FiClock, FiEdit3 } from 'react-icons/fi';

// Internal Dependencies
import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { CalendarToday, SensorsRounded } from '@mui/icons-material';
import { EngagementTopBar } from './EngagementCardTopBar';
import { EngagementCard } from './EngagementCard';
import { MiddleValign } from '@keyops-hcp/ui-components';

export const ActiveAdBoardCard = ({
  adBoardInvite,
}: {
  adBoardInvite: AdBoardInviteDto;
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  const duration = adBoardInvite.adBoard.sectionGroups.reduce(
    (sum, sectionGroup) => (sum += sectionGroup.estimatedTime),
    0
  );
  const startDate = adBoardInvite.adBoard.startDate
    ? new Date(adBoardInvite.adBoard.startDate).toLocaleDateString(
        i18n.language,
        {
          month: 'short',
          day: 'numeric',
        }
      )
    : undefined;
  const endDate = adBoardInvite.adBoard.endDate
    ? new Date(adBoardInvite.adBoard.endDate).toLocaleDateString(
        i18n.language,
        {
          month: 'short',
          day: 'numeric',
        }
      )
    : undefined;

  const getActionLabel = () => {
    if (
      adBoardInvite.state === 'contract-signed' &&
      adBoardInvite.adBoard.state === 'live'
    ) {
      return t('dashboard.activeEngagementsSection.adBoard.cta.launch');
    }
    return t('dashboard.activeEngagementsSection.adBoard.cta.learnMore');
  };

  const getStatusCallout = (): { icon?: ReactNode; text: string } => {
    const iconStyle = {
      color: theme.palette.keyops.teal,
      marginRight: '8px',
    };
    switch (adBoardInvite.state) {
      case 'sent': {
        return {
          text: t(
            'dashboard.activeEngagementsSection.adBoard.statusCallout.sent'
          ),
        };
      }
      case 'accepted': {
        return {
          icon: <FiEdit3 style={iconStyle} />,
          text: t(
            'dashboard.activeEngagementsSection.adBoard.statusCallout.accepted'
          ),
        };
      }
      case 'contract-signed': {
        if (adBoardInvite.adBoard.state === 'planning') {
          return {
            icon: <FiCheckCircle style={iconStyle} />,
            text: t(
              'dashboard.activeEngagementsSection.adBoard.statusCallout.planning'
            ),
          };
        } else if (adBoardInvite.adBoard.state === 'live') {
          return {
            icon: <SensorsRounded style={iconStyle} />,
            text: endDate
              ? t(
                  'dashboard.activeEngagementsSection.adBoard.statusCallout.live',
                  {
                    endDate,
                  }
                )
              : t(
                  'dashboard.activeEngagementsSection.adBoard.statusCallout.liveNoEndDate'
                ),
          };
        }
      }
    }
    return { text: '' };
  };

  const statusCallout = getStatusCallout();
  return (
    <EngagementCard
      onClick={() => navigate('/adboard-landing/' + adBoardInvite.adBoardId)}
      actionLabel={getActionLabel()}
      statusCallout={
        <MiddleValign>
          {statusCallout.icon}
          <span>{statusCallout.text}</span>
        </MiddleValign>
      }
    >
      <Stack spacing={1}>
        <EngagementTopBar type="adBoard">
          <Typography fontSize="14px" fontWeight={600}>
            {t('dashboard.activeEngagementsSection.earn')} $
            {adBoardInvite.honorarium}
          </Typography>
          {duration > 0 && (
            <MiddleValign>
              <FiClock
                style={{
                  fontSize: '16px',
                  color: theme.palette.keyops.blue.dark,
                  marginRight: '8px',
                }}
              />
              {duration}
              {t('dashboard.activeEngagementsSection.minutes')}
            </MiddleValign>
          )}
          {startDate && (
            <MiddleValign>
              <CalendarToday
                sx={{
                  fontSize: 16,
                  mr: 1,
                  color: theme.palette.keyops.blue.dark,
                }}
              />{' '}
              {startDate} {endDate && <>- {endDate}</>}
            </MiddleValign>
          )}
        </EngagementTopBar>
        <Typography>{adBoardInvite.adBoard.company.name}</Typography>
        <Typography
          variant="body1"
          color={theme.palette.keyops.black.main}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {adBoardInvite.adBoard.title}
        </Typography>
      </Stack>
    </EngagementCard>
  );
};
