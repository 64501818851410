import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
import { SectionActivitiesView } from '../SectionActivitiesView';
import { Loader } from '../Loader';
import i18next from '../../languages/i18n.config';

interface SectionNavigationContentProps {
  handleSectionDrawerToggle: () => void;
  setDiscussionDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayedDiscussionId: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSectionId: (id: string) => void;
}

export const SectionNavigationContent = ({
  handleSectionDrawerToggle,
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
  setCurrentSectionId,
}: SectionNavigationContentProps): JSX.Element => {
  const {
    isAdmin,
    isLoading,
    isError,
    sectionGroupsData,
    adBoardProgress,
    finishedSections = [],
  } = (useAdBoardContext() as HCPAdBoardContextType) || {};

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Box
        p={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant='body1' fontWeight={500}>
          {i18next.t('ad_board.sectionsDrawer.adBoardContent')}
        </Typography>
        <IconButton size='small' onClick={handleSectionDrawerToggle}>
          <CloseIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>
      <Divider />
      <Box flexGrow={1}>
        {isLoading && <Loader size='3rem' pt={2} />}
        {(!sectionGroupsData || sectionGroupsData.length <= 0) && <>No data</>}
        {sectionGroupsData &&
          sectionGroupsData.length > 0 &&
          sectionGroupsData.map((sectionGroup) => (
            <SectionActivitiesView
              key={sectionGroup.id}
              sectionGroup={sectionGroup}
              sectionProgress={
                adBoardProgress?.progressDetails?.sectionProgress
              }
              setDisplayedDiscussionId={setDisplayedDiscussionId}
              setDiscussionDrawerOpen={setDiscussionDrawerOpen}
              finishedSections={finishedSections}
              setCurrentSectionId={setCurrentSectionId}
              isAdmin={isAdmin}
            />
          ))}
        <Divider sx={{ mt: 2 }} />
        {/* TODO: Add moderators UI here */}
      </Box>
    </>
  );
};
