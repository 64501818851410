// External Dependencies
import React, { useEffect } from 'react';
import { Stack, Link, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

// Internal Dependencies
import { LOGIN } from '../../utils/routes';
import { axiosCall } from '../../utils/api';
import Loading from '../../components/Loading';
import {
  analytics,
  KeyopsHeader1,
  KeyopsHeader2,
} from '@keyops-hcp/ui-components';
import { SURVEY_EMAIL_ADDRESS } from '../../utils/constants';

const VerifyWorkEmail = () => {
  const theme = useTheme();
  const { verifyToken } = useParams();

  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [verified, setVerified] = React.useState(false);

  const getData = async () => {
    const results = await axiosCall(
      true,
      'post',
      'user/verifyWorkEmail',
      null,
      null,
      {
        state: {
          token: verifyToken,
        },
      }
    );
    setVerified(results.status === 200);
    analytics.track(`Verify Work Email`, { verified });
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const ContinueToLogIn = (
    <Link underline={'none'} href={LOGIN}>
      <KeyopsHeader2
        color={theme.palette.keyops.blue.main}
        align="center"
        paddingTop="16px"
      >
        {t('verifyWorkEmail.continueToLogIn')}
      </KeyopsHeader2>
    </Link>
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Stack
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100vh'}
    >
      <KeyopsHeader1 align="center" sx={{ width: { xs: '95%', sm: '60%' } }}>
        {verified ? (
          t('verifyWorkEmail.successfulVerification')
        ) : (
          <Trans
            defaults={t('verifyWorkEmail.failedVerification')}
            components={{
              paragraphTag: <p />,
              linkTag: (
                <Link
                  href={`mailto:${SURVEY_EMAIL_ADDRESS}?subject=Verification support`}
                  style={{ textDecoration: 'none' }}
                />
              ),
            }}
          />
        )}
      </KeyopsHeader1>
      {ContinueToLogIn}
    </Stack>
  );
};

export default VerifyWorkEmail;
