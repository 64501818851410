import { Theme } from '@mui/material';

const buttonCommon = {
  borderRadius: '8px',
  display: 'block',
  px: 4,
};

export const useStyles = (theme: Theme) => ({
  buttonAccept: {
    ...buttonCommon,
    color: theme.palette.keyops.blue.dark,
  },
  buttonDecline: {
    ...buttonCommon,
    color: theme.palette.keyops.gray[500],
  },
  helperText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.keyops.gray[700],
  },
  buttonText: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.keyops.black.main,
  },
});
